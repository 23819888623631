<template>
	<v-container fluid>
		<v-col v-if="news">
			<v-card
				width="100%"
				height="60"
				color="brown darken-3"
			>
				<v-row
					class="ma-0 ml-5 news fill-height"
					align="center"
				>
					<span class="news_title">NEW</span> <span class="mx-3">|</span> 품질보증등록
					<h3 class="ml-2">{{ count?.guaranteecount }}</h3>
					건 <span class="mx-3">|</span> AS신청
					<h3 class="ml-2">{{ count?.ascount }}</h3>
					건 <span class="mx-3">|</span> 1:1문의
					<h3 class="ml-2">{{ count?.askcount }}</h3>
					건 <span class="mx-3">|</span> 인테리어점 가입 승인 요청
					<h3 class="ml-2">{{ count?.corporationcount }}</h3>
					건 <span class="mx-3">|</span> 전문가집들이 승인 요청
					<h3 class="ml-2">{{ count?.expcount }}</h3>
					건 <span class="mx-3">|</span> 인테리어점 문의
					<h3 class="ml-2">{{ count?.intercount }}</h3>
					건
					<v-spacer />
					<v-btn
						dark
						icon
						class="mr-3"
						@click="news = false"
					>
						<v-icon> clear </v-icon>
					</v-btn>
				</v-row>
			</v-card>
		</v-col>
		<v-row class="ma-0">
			<v-col
				cols="12"
				md="4"
			>
				<CommonListsList01
					title="품질보증등록"
					count-title="신규"
					count-unit="건"
					header-title-left="상태"
					header-title-center="품질보증번호"
					header-title-right="등록일"
					link="/admin/custom/quality"
					left="7"
					right="5"
					:items="guarantee"
				/>
			</v-col>
			<v-col
				cols="12"
				md="4"
			>
				<CommonListsList01
					title="A/S신청"
					count-title="신규"
					count-unit="건"
					header-title-left="상태"
					header-title-center="분류"
					header-title-right="등록일"
					link="/admin/custom/as"
					left="5"
					right="7"
					:items="serviceas"
				/>
			</v-col>
			<v-col
				cols="12"
				md="4"
			>
				<CommonListsList01
					title="1:1문의"
					count-title="신규"
					count-unit="건"
					header-title-left="상태"
					header-title-center="유형"
					header-title-right="접수일"
					link="/admin/custom/question"
					left="7"
					right="5"
					:items="serviceask"
				/>
			</v-col>
		</v-row>
		<v-row class="ma-0">
			<v-col
				cols="12"
				md="4"
			>
				<CommonListsList01
					:title="interiorTab === 0 ? '인테리어점 가입 승인 요청' : '인테리어점 문의'"
					count-title="신규"
					count-unit="건"
					header-title-left="상태"
					header-title-center="업체명"
					header-title-right="등록일"
					link="/admin/user/interior"
					left="5"
					right="7"
					:items="interiorTab === 0 ? corporation : interservice"
					:tab-items="interiorTabItems"
					@tabClicked="interiorTab = $event"
				/>
			</v-col>
			<v-col
				cols="12"
				md="4"
			>
				<CommonListsList01
					title="전문가 집들이 승인 요청"
					count-title="신규"
					count-unit="건"
					header-title-left="가입 상태"
					header-title-center="업체명"
					header-title-right="가입일"
					link="/admin/display/houseSpecial"
					left="5"
					right="7"
					:items="exphousewarming"
					:tab-items="[]"
				/>
			</v-col>
			<v-col
				cols="12"
				md="4"
				class="pa-0 px-3"
			>
				<v-row
					class="ma-0 py-3"
					style="height: 130px"
				>
					<v-col
						cols="4"
						class="list_title pa-0 pt-4"
					>
						방문자 통계
					</v-col>
					<v-col
						cols="12"
						sm="8"
						class="d-flex align-end pa-0"
					>
						<CommonTabsTab01
							v-model="visitTab"
							:tab-items="visitTabItems"
							class-name="margin_0"
						/>
					</v-col>
				</v-row>
				<v-card
					width="100%"
					height="355"
					color="white"
					class="pa-5"
					elevation="1"
				>
					<v-col>
						<CommonChartsBarChart
							height="305"
							:value="filteredStatistics"
						/>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
export default {
	name: 'AdminHome',
	data: () => ({
		news: true,
		interiorTitle: ['인테리어점 가입 승인 요청', '인테리어점 문의'],
		interiorTab: 0,
		interiorTabItems: ['인테리어점 가입', '인테리어점 문의'],
		visitTab: null,
		visitTabItems: ['일별', '월별', '연별'],
		count: {},
		guarantee: [],
		serviceas: [],
		serviceask: [],
		corporation: [],
		exphousewarming: [],
		interservice: [],
		statistics: [],
	}),
	computed: {
		filteredStatistics() {
			return this.statistics.filter(item => {
				if (this.visitTab === 0) return item.type === 'day'
				else if (this.visitTab === 1) return item.type === 'month'
				else if (this.visitTab === 2) return item.type === 'year'
			})
		},
	},
	watch: {},
	created() {
		this.getList()
	},
	mounted() {},
	methods: {
		...mapActions(['ADMIN_ACT_STATISTICS_DASHBOARD']),
		async getList() {
			this.loading = true
			await this.ADMIN_ACT_STATISTICS_DASHBOARD().then(res => {
				this.count = res.count
				this.guarantee = res.guarantee
				this.serviceas = res.serviceas
				this.serviceask = res.serviceask
				this.corporation = res.corporation
				this.exphousewarming = res.exphousewarming
				this.interservice = res.interservice
				this.statistics = res.statistics
				this.loading = false
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.news {
	color: white;
	font-size: 1.2rem;
	font-family: 'NotoSansKR-Regular';
	&_title {
		color: aqua;
		font-size: 1.4rem;
		font-family: 'NotoSansKR-Bold';
	}
	h3 {
		color: #de7f7f;
		font-size: 1.4rem;
		font-family: 'NotoSansKR-Bold';
	}
}
.list {
	&_bg {
		overflow: hidden;
		overflow-y: auto;
	}
	&_title {
		font-size: 1.2rem;
		font-family: 'NotoSansKR-Bold';
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 100%;
	}

	&_text {
		font-size: 1rem;
		font-family: 'NotoSansKR-Regular';
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 100%;
	}
}
</style>
